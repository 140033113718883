import { useRef } from 'react';
import Grid from '../components/Grid';
import { GroupInput, Input } from '../components/Input';
import { showConfirm } from '../components/Messages';
import { useSystemContext } from '../Context/SystemContext';

export const AppFormularios = () => {
    const System = useSystemContext();
    const gridRef = useRef();
    const handleChangeActive = async (id) => {
        showConfirm('Este processo ira desativar outros formulários, Prosseguir?', async () => {
            const response = await System.apiPut('/appform/' + id + '/active', {});
            if (response.status === 200) {
                gridRef.current.methods.GridRefresh();
            } else {
                System.methods.notificationError('Erro ao ativar registro!');
            };
        });
    }

    const gridRequisito = (formid) => {
        return (
            <Grid
                url={`/appform/${formid}/requisito`}
                perms={'1'}
                externalRef={gridRef}
                disableModal={true}
                forceReloadAfterSave={true}
                GridHeaders={[
                    { title: 'Requisito', field: 'requisito', sort: true, className: 'w-5/6 text-left pl-1' },
                ]}
                LineDataPrint={(data) => {
                    return [
                        data.requisito
                    ];
                }}
                onRenderForm={(GridModes, SetFieldValue, values) => {
                    return (
                        <div>
                            <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                            </div>
                            <GroupInput>
                                <Input name="requisito" label="Requisito" />
                            </GroupInput>
                        </div>
                    );
                }}
                FormTabs={
                    [
                        () => {
                            return <div>Requisitos</div>;
                        },
                        () => {
                            return <div>Itens</div>;
                        },
                    ]}
                onRenderFormExtra={
                    [
                        (GridModes, item, GridRefresh) => {
                            // Requisitos
                            return (
                                <div name="acaoImediata" className={`bg-white pb-3`}>
                                    <div>
                                        {gridRequisitoItem(formid, item.id)}
                                    </div>
                                </div>
                            );
                        },
                    ]}

            />);
    }

    const gridRequisitoItem = (formid, requisito) => {
        return (
            <Grid
                url={`/appform/${formid}/requisito/${requisito}/item`}
                perms={'1'}
                externalRef={gridRef}
                disableModal={true}
                GridHeaders={[
                    { title: 'Item', field: 'item', sort: true, className: 'w-5/6 text-left pl-1' },
                ]}
                LineDataPrint={(data) => {
                    return [
                        data.item
                    ];
                }}
                onRenderForm={(GridModes, SetFieldValue, values) => {
                    return (
                        <div>
                            <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                            </div>
                            <GroupInput>
                                <Input name="item" label="Item" />
                            </GroupInput>
                        </div>
                    );
                }}
            />);
    }


    return <div className="mt-4 ml-2 bg-white grow">
        <Grid
            url={'/appform'}
            perms={'1'}
            externalRef={gridRef}
            disableModal={true}
            GridHeaders={[
                { title: 'Formulário', field: 'titulo', sort: true, className: 'w-5/6 text-left pl-1' },
                { title: 'Ativo', field: 'ativo', sort: true, filter: false, className: 'w-1/6 text-center pl-1' },
            ]}
            LineDataPrint={(data) => {
                return [
                    data.titulo,
                    !data.ativo ?
                        <span
                            onClick={() => handleChangeActive(data._id)}
                            className="w-12 px-2 border border-yellow-700 rounded hover:bg-yellow-900 hover:text-white bg-amarelo-eq"
                        >
                            Ativar
                        </span>
                        : 'Ativo'
                ];
            }}
            onRenderForm={(GridModes, SetFieldValue, values) => {
                return (
                    <div>
                        <div className="inline-flex w-full h-8 p-2 py-1 justify-left bg-amarelo-eq">
                            Formulário: <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                        </div>
                        <GroupInput>
                            <Input name="titulo" label="Titulo" />
                        </GroupInput>
                    </div>
                );
            }}
            FormTabs={
                [
                    () => {
                        return <div>Formulário</div>;
                    },
                    () => {
                        return <div>Requisitos</div>;
                    },
                ]}
            onRenderFormExtra={
                [
                    (GridModes, item, GridRefresh) => {
                        // Requisitos
                        return (
                            <div name="acaoImediata" className={`bg-white pb-3`}>
                                <div>
                                    {gridRequisito(item._id)}
                                </div>
                            </div>
                        );
                    },
                ]}
        />
    </div >
        ;
}
