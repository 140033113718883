import { Fragment, useEffect, useRef, useState } from 'react';
import { RiLogoutBoxRLine, RiUser2Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import { CameraInput, CheckBoxInput, DateInput, DBSelectInput, FormInput, TextInput } from '../components/Input';
const yup = require('../interface/yup-br');


const Mobile = () => {
    let System = useSystemContext();
    let navigate = useNavigate();
    const [logo, setLogo] = useState();
    const [formActive, setFormActive] = useState({});
    const fileInputRef = useRef(null);


    useEffect(() => {
        (async () => {
            try {
                setLogo(await System.methods.getCompanyLogo());
                const response = await System.apiGet('/appformactive');
                if (response.status === 200) {
                    setFormActive(response.data[0]);
                }
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="h-screen text-sm bg-white">
            <div className="flex w-full h-14 bg-fundo-menu-top text-fonte-menu-top ">
                <div className="left-0 min-w-120 min-h-53 ">
                    {logo && (
                        <img src={logo} onClick={() => navigate('/database', { replace: false })} alt="logo" className="mt-1 overflow-hidden cursor-pointer w-28 max-w-120 max-h-53" />
                    )}
                </div>
                <div className='inline-flex items-center justify-center mx-auto '>
                    <RiUser2Line className="mr-1" />
                    <span>{System.property.user?.name}</span>
                </div>
                <div className='ml-auto mr-2 cursor-pointer' onClick={() => { System.logout(); navigate('/login') }}>
                    <RiLogoutBoxRLine size={24} className='mt-2' />
                    <span className=''>Sair</span>
                </div>
            </div>

            <div className="">
                <div className='w-full text-center border-b border-fundo-menu-top bg-yellow-50'>
                    <h1 className="text-2xl font-bold">Desvios QSMS</h1>
                </div>
                <div className="bg-white">
                    <FormInput
                        document={`/appsubmitform`}
                        canEdit={true}
                        canInsert={true}
                        submitText="Enviar formulário (Send data)"
                        limparStrings={true}
                        onlypost={true}
                        onPrepareSaveRecord={(values) => {
                            values['formid'] = formActive?._id;
                            return values;
                        }}
                        submitButtonClass={'w-full ml-4 h-8 text-black rounded-md shadow-md border-1 bg-botao-primario hover:bg-botao-primario-hover hover:text-gray-100'}
                        validationSchema={yup.object().shape({
                            colaboradorid: yup.string(),
                            areaid: yup.string().required(),
                            data: yup.date().required(),
                            detalhamento: yup.string().required('Informe o detalhamento'),
                            acaoimediata: yup.string(),
                            attachment: yup.mixed(),
                        })}
                        onRenderFields={(changeFieldValue, values) => {
                            return (
                                <Fragment>
                                    <DBSelectInput
                                        name="areaid"
                                        label="Área (Area)"
                                        url={'/areauser'}
                                        displaySelectField="area"
                                    />
                                    <DateInput name="data" label="Data (Date)" />
                                    <div>
                                        {formActive?.requisitos?.map((requisito, index) => (
                                            <div key={index} className="w-full">
                                                <div className="w-full p-2 text-left ">
                                                    <span className='font-semibold'>
                                                        {index + 1}. {requisito.requisito}
                                                    </span>
                                                    {requisito.items.map((item, indexItem) => (
                                                        <CheckBoxInput
                                                            key={uuid()}
                                                            className="w-full mr-2"
                                                            name={`requisito_${requisito._id}_${item._id}`}
                                                            label={item.item}
                                                        />
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <TextInput name="detalhamento" label="Detalhamento (Description)" className="w-full" rows="5" />
                                    <DBSelectInput
                                        name="colaboradorid"
                                        label="Nome (Name) * Campo OPCIONAL (Optional field)"
                                        url={'/crewactive'}
                                        displaySelectField="nome"
                                    />
                                    <TextInput name="acaoimediata" label="Ação imediata (Immediate action) " className="w-full" rows="5" />
                                    <br />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        ref={fileInputRef}
                                        className="hidden"
                                    />

                                    <CameraInput
                                        name="attachment"
                                        canEdit={true}
                                        canInsert={true}
                                        canDelete={false}
                                        label="Imagem (Image)"

                                    />
                                </Fragment>
                            );
                        }}
                    />
                </div>
            </div>
        </div >
    );
};

export default Mobile;