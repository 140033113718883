import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { GroupInput, Input } from '../components/Input';

import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
const yup = require('../interface/yup-br');
const moment = require('moment');

// requisito_669ac6376b6d3cc84ea62a25_669afe3f14297cdb2bdb871d : true

export const AppSubmitFormChart = () => {
    let System = useSystemContext();
    const [appSubmitForms, setAppSubmitForms] = useState([]);
    const [chartJSX, setChartJSX] = useState(null);
    const [periodoInicio, setPeriodoInicio] = useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    const [periodoFinal, setPeriodoFinal] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));

    useEffect(() => {
        (async () => {
            const response = await System.apiGet(`/appsubmitform`);
            if (response.status === 200) setAppSubmitForms(response.data);
        })();
        // eslint-disable-next-line
    }, []);

    const GetPeriodo = (fnClickExecutar) => {
        return (
            <Formik
                initialValues={{
                    inicio: periodoInicio,
                    final: periodoFinal,
                }}
                onSubmit={(values) => {
                    setPeriodoInicio(values.inicio);
                    setPeriodoFinal(values.final);
                    setChartJSX(fnClickExecutar(values.inicio, values.final));
                }}
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize={true}
                validationSchema={yup.object({
                    inicio: yup.date().required('Data inicial é obrigatória'),
                    final: yup.date().required('Data final é obrigatória'),
                })
                }
            >
                {({ values, errors, setErrors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        <GroupInput>
                            <Input
                                type="date"
                                label="Data Inicial"
                                key={uuidv4()}
                                name="inicio"
                                className="w-min"
                            />
                            <Input
                                type="date"
                                label="Data Final"
                                id={uuidv4()}
                                name="final"
                                className="w-min"
                            />
                            <div className="flex flex-col">
                                <button
                                    type="submit"
                                    className="px-4 py-2 text-white rounded-md mt-7 bg-amarelo-eq"
                                >
                                    Buscar dados
                                </button>
                            </div>
                        </GroupInput>
                    </Form>
                )}
            </Formik>
        );
    }

    const Mensal = () => {
        const dataMensal = [];
        appSubmitForms.forEach((item) => {
            const data = moment(item.data.substring(0, 10));
            const dataStr = data.format('MM/YYYY');
            const existingData = dataMensal.find((itemMes) => itemMes.name === dataStr);
            if (existingData) {
                existingData.uv += 1;
            } else {
                dataMensal.push({ name: dataStr, uv: 1 });
            }
        });

        dataMensal.sort((a, b) => a.name.localeCompare(b.name));

        const ticks = [];
        let highestUvItem = {};
        if (dataMensal.length > 0) {
            highestUvItem = dataMensal.reduce((max, item) => {
                if (!item.hasOwnProperty('uv')) {
                    console.error("Um item em dataMensal não possui a propriedade 'uv':", item);
                    return max;
                }
                return item.uv > max.uv ? item : max;
            }, dataMensal[0]);
        }
        let maxUV = highestUvItem.uv;

        maxUV += maxUV % 2 === 0 ? 2 : 3;
        for (let i = 0; i < maxUV; i += 2) ticks.push(i);

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={150}
                    height={200}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    data={dataMensal}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis ticks={ticks} />
                    <Tooltip formatter={(value, name) => [value]} />
                    <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        );
    }

    const Usuario = () => {
        const dataUsuario = [];
        let maxUV = 0;
        appSubmitForms.forEach((item) => {
            const dataStr = item?.crew?.nome;
            const existingData = dataUsuario.find((item) => item.name === dataStr);
            if (existingData) {
                existingData.uv += 1;
                if (existingData.uv > maxUV) maxUV = existingData.uv;
            } else {
                dataUsuario.push({ name: dataStr, uv: 1 });
            }
        });

        let COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF00AA', '#00AAFF'];
        if (COLORS.length < dataUsuario.length) {
            const additionalColors = Array(dataUsuario.length - COLORS.length).fill().map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
            COLORS.push(...additionalColors);
        }

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };


        return (
            <PieChart width={500} height={270}>
                <Pie
                    data={dataUsuario}
                    cx={130}
                    cy={130}
                    labelLine={false}
                    innerRadius={0}
                    outerRadius={130}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="uv"
                    label={renderCustomizedLabel}
                >
                    {dataUsuario.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" align="right" verticalAlign="middle" />
            </PieChart>
        );
    }

    const Area = () => {
        const dataUsuario = [];
        let maxUV = 0;
        appSubmitForms.forEach((item) => {
            const dataStr = item.area.area;
            const existingData = dataUsuario.find((item) => item.name === dataStr);
            if (existingData) {
                existingData.uv += 1;
                if (existingData.uv > maxUV) maxUV = existingData.uv;
            } else {
                dataUsuario.push({ name: dataStr, uv: 1 });
            }
        });

        let COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF00AA', '#00AAFF'];
        if (COLORS.length < dataUsuario.length) {
            const additionalColors = Array(dataUsuario.length - COLORS.length).fill().map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
            COLORS.push(...additionalColors);
        }

        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };


        return (
            <PieChart width={500} height={270}>
                <Pie
                    data={dataUsuario}
                    cx={130}
                    cy={130}
                    labelLine={false}
                    innerRadius={0}
                    outerRadius={130}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="uv"
                    label={renderCustomizedLabel}
                >
                    {dataUsuario.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" align="right" verticalAlign="middle" />
            </PieChart>
        );
    }

    const PizzaItems = (data) => {
        const dataUsuario = [];
        Object.keys(data).forEach((key) => {
            dataUsuario.push(data[key]);
        });
        let COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF00AA', '#00AAFF'];
        if (COLORS.length < dataUsuario.length) {
            const additionalColors = Array(dataUsuario.length - COLORS.length).fill().map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`);
            COLORS.push(...additionalColors);
        }
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };

        return (
            <PieChart width={500} height={200} key={uuidv4()}>
                <Pie
                    data={dataUsuario}
                    cx={75}
                    cy={80}
                    labelLine={false}
                    innerRadius={0}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="total"
                    label={renderCustomizedLabel}
                >
                    {dataUsuario.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" align="right" verticalAlign="middle" />
            </PieChart>
        );
    }

    const DataRendererTotais = ({ totais }) => {
        // Função recursiva para renderizar os dados
        const renderData = (obj, level = 0) => {
            return (
                <ul style={{ paddingLeft: `${level * 20}px` }} key={uuidv4()}> {/* Indenta com base no nível */}
                    {Object.keys(obj).map((key) => {
                        const item = obj[key];

                        if (item.name) {
                            return (
                                <li key={uuidv4()} className={`font-semibold mt-8`}>
                                    <span className={`p-2 ${item.soma ? 'border bg-gray-100' : 'ml-2 text-lg'}`}>
                                        {item.name} {item.soma !== undefined ? `(considerando ${item.soma} desvios submetidos)` : ''}
                                    </span>
                                    {item.requisito && PizzaItems(item.item)}

                                    {/* Renderiza os subníveis se existirem */}
                                    {Object.keys(item)
                                        .filter((k) => k !== '_id' && k !== 'titulo' && k !== 'total' && k !== 'count')
                                        .length > 0 && renderData(item, level + 1)} {/* Passa o nível para identar */}
                                </li>
                            );
                        }

                        return null;
                    })}
                </ul>
            );
        };

        return <div>{renderData(totais)}</div>;
    };


    const AnaliseItens = (startDate, endDate) => {
        let totais = {};
        appSubmitForms.forEach((enviado) => {
            if (moment(enviado.data).isBetween(startDate, endDate, null, '[]')) {
                if (enviado.formid) {
                    if (!totais[enviado.formid]) {
                        totais[enviado.formid] = { name: enviado.form?.titulo, soma: 0 };
                    }
                    totais[enviado.formid].soma += 1;
                    //console.log('enviados -->', enviado.form?.titulo);
                    enviado.form?.requisitos?.forEach((requisito) => {
                        if (!totais[enviado.formid][requisito._id]) {
                            totais[enviado.formid][requisito._id] = { name: requisito.requisito, requisito: true, item: [] };
                        }
                        //console.log('   requisito -->', requisito.requisito);
                        requisito?.items.forEach((item) => {
                            if (!totais[enviado.formid][requisito._id]['item'][item._id]) {
                                totais[enviado.formid][requisito._id]['item'][item._id] = { name: item.item, total: 0 };
                            }
                            if (enviado[`requisito_${requisito._id}_${item._id}`]) {
                                totais[enviado.formid][requisito._id]['item'][item._id].total += 1;
                            }
                            //console.log('     item -->', item.item, enviado[`requisito_${requisito._id}_${item._id}`]);
                        });
                    });
                }
            }
        });
        //console.log('------------------');
        //console.log(JSON.stringify(totais, null, 2));
        return (
            <div className=''>
                {DataRendererTotais({ totais })}
            </div>
        );
    }


    return (
        <div className='p-2 h-fit'>
            <Tabs index={1} name="tabs">
                <TabList>
                    <Tab key={uuidv4()}>
                        Mensal
                    </Tab>
                    <Tab key={uuidv4()}>
                        Usuário
                    </Tab>
                    <Tab key={uuidv4()}>
                        Área
                    </Tab>
                    <Tab key={uuidv4()}>
                        Itens
                    </Tab>
                </TabList>
                <TabPanel key={uuidv4()}>
                    <div className='h-[650px] w-full'>
                        <span className='ml-2 text-xl font-semibold'>
                            Desvios por mês (modelo)
                        </span>
                        <div className='h-[350px]'>
                            {Mensal()}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key={uuidv4()}>
                    <div className='h-[650px] w-full ml-4'>
                        <span className='ml-2 text-xl font-semibold'>
                            Registro de desvios por usuário
                        </span>
                        <div className='h-[450px] pt-4 pl-4'>
                            {Usuario()}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key={uuidv4()}>
                    <div className='h-[650px] w-full ml-4'>
                        <span className='ml-2 text-xl font-semibold'>
                            Registro de desvios por área
                        </span>
                        <div className='h-[450px] pt-4 pl-4'>
                            {Area()}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel key={uuidv4()}>
                    <div className='h-[950px] w-full ml-4'>
                        <span className='ml-2 text-xl font-semibold'>
                            Análise de desvios por item
                        </span>
                        <div>
                            {GetPeriodo(AnaliseItens)}
                        </div>
                        {chartJSX &&
                            <div className='h-[900px] w-full ml-4'>
                                {chartJSX}
                            </div>
                        }
                    </div>
                </TabPanel>
            </Tabs>
        </div >);
}
