import { Form, Formik } from 'formik';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSystemContext } from '../Context/SystemContext';
import { GroupInput, Input } from '../components/Input';
import '../styles.css';

import fundoDesvios from '../assets/background_desvios.png';
import logoDesvios from '../assets/logo_desvios.png';

const yup = require('../interface/yup-br');

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

export default function Login() {
    const { login } = useSystemContext();
    const [message, setMessage] = useState('');

    async function handleLogin(values) {
        let response = await login(values);
        if (response.status === 200) {
            window.location.href = '/';
        } else {
            console.log('@@@@', response);
            setMessage('Usuário ou senha inválidos');
            localStorage.removeItem('token');
        }
    }
    return (
        <section className="flex items-center w-screen h-screen" style={{ backgroundImage: `url(${fundoDesvios})`, backgroundSize: 'cover' }}>
            <div className="p-10 mx-auto text-white rounded shadow-md w-72">
                <div className="py-2 text-center">
                    <img src={logoDesvios} alt="logo" />
                </div>
                <div className="py-2 text-2xl text-center">Login</div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={isMobile ? null : schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            handleLogin(values);
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    <Form>
                        <div className='caret-black'>
                            {isMobile ?
                                <GroupInput>
                                    <Input name="token" type="text" label="Token" className="text-black" labelClassName="text-white" />
                                </GroupInput>
                                :
                                <div>
                                    <GroupInput>
                                        <Input name="email" type="email" label="Email" className="text-black" labelClassName="text-white" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="password" type="password" label="Senha" className="text-black" labelClassName="text-white" />
                                    </GroupInput>
                                </div>
                            }
                            <div className="w-full py-2 text-center text-gray-800">
                                <button
                                    type="submit"
                                    className="w-3/4 py-2 border rounded shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                                >
                                    Enviar
                                </button>
                            </div>
                            <div className="w-full text-center text-red-500">{message}</div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </section>
    );
}
